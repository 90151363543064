import React, {useState, useEffect, useRef} from "react"
import styled from 'styled-components';
import LandingPage from 'src/components/landing-page/LandingPage'

const IndexPage = ({
  ...rest
}) => {

  return (
    <LandingPage
      productTerm='Resume Builder'
      pageName='Home'
      resumeTerm={{
        lowercasedSingular: 'resume',
        capitalizedSingular: 'Resume',
        lowercasedPlural: 'resumes',
        capitalizedPlural: 'Resumes',
      }}
      {...rest}
    />
  )
}

export default IndexPage
